import React from 'react';
import './App.css';
import Home from './components/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Upholstery from './components/Upholstery';
import ReUpholstery from './components/ReUpholstery';
import {Header} from './components/Header';

class App extends React.Component {

  constructor(props){
    super(props);
    this.state ={

    };
  }


  render() {
    return (
      <div className="App">
        <header className="App-header">
        <Header />
      <div>
        <Switch>
          <Route exact path="/about" render= {()=>(<AboutUs {...this.props} />)} />
          <Route exact path="/services" render = { () => (<Services  {...this.props}/>)} />
          {/* <Route exact path="/services/upholstery" render = {() =>(<Upholstery {...this.props} />)}/>
          <Route exact path="/services/reupholstery" render = {() =>(<ReUpholstery {...this.props} />)}/> */}
          <Route exact path="/reupholstery" render = {() =>(<ReUpholstery {...this.props} />)}/>
          <Route exact path="/upholstery" render = {() =>(<Upholstery {...this.props} />)}/>
          <Route exact path="/contact" render = { () => (<ContactUs {...this.props} />)} />
          <Route path="/" render={() => ( <Home />)} />
        </Switch>
      </div>
        </header>
      </div>
    );
  }
}

export default withRouter(App);
