import React, { Component } from 'react';
import CommonUtils from '../utils/CommonUtils';


class Upholstery extends Component {
    componentDidMount = () => {
        CommonUtils.toggleTab("services");
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12 text-center">
                            <div id="carousel-example-generic" className="carousel slide" style={{ width: '600px', margin: '0 auto' }}>
                                {/* Indicators */}
                                <ol className="carousel-indicators hidden-xs">
                                    <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                                    <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                                    <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                                </ol>

                                {/* Wrapper for slides  */}
                                <div className="carousel-inner">
                                    <div className="item active">
                                        <img className="img-responsive img-full" src="./images/reupholstery/reupholstery-4.jpg" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-responsive img-full" src="images/reupholstery/reupholstery-3.jpg" alt="" />
                                    </div>
                                    <div className="item">
                                        <img className="img-responsive img-full" src="images/reupholstery/reupholstery-6.jpg" alt="" />
                                    </div>
                                </div>

                                {/* Controls */}
                                <a className="left carousel-control" href="#carousel-example-generic" data-slide="prev">
                                    <span className="icon-prev"></span>
                                </a>
                                <a className="right carousel-control" href="#carousel-example-generic" data-slide="next">
                                    <span className="icon-next"></span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12 text-center ">
                            <h2 className="brand-before center-block">
                                <small>Welcome to</small>
                            </h2>
                            <h1 className="brand-name" ><img className="img-responsive center-block" src="images/index-images/balushome_png.png" /> </h1>
                            <hr className="tagline-divider" />
                            <h2>
                                <small>Reg.
                        <strong>Al Shuome Upholstery</strong>
                                </small><br />
                                <small><strong>Services | Leather & Fabric Upholstery</strong></small>

                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="box">
                        <div className="col-lg-4">
                            <img className="img-responsive  img-responsive img-full img-left" src="images/reupholstery/reupholstery-7.jpg" alt="" style={{ paddingTop: '70px' }} />
                        </div>
                        <div className="col-lg-8">
                            <hr />
                            <h2 className="intro-text text-center">
                                <strong>Leather and Fabric Upholstery</strong>
                            </h2>
                            <hr />
                            <p style={{ lineheight: '190%' }} >Al Shumoe upholstery has been serving as a furniture and furnishing supplier in the Bahrain market for over 10 years. Our superior quality products and services offered to our clients have made us a reputed, trustworthy and a famous furniture firm in Bahrain.</p>


                        </div>


                    </div>

                    <div className="row">
                        <div className="box">
                            <div className="col-lg-12">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>Upholstery Service</strong>
                                </h2>
                                <hr />
                                <p style={{lineheight: '190%'}}>We can upholster any item of furniture, be it leather or fabric, including; Sofa, Couch, Office furniture, Dining chairs, Kitchen chairs, Furniture for Hotels, Restaurants etc</p>

                            </div>
                            <div className="col-lg-12">
                                <div className="col-lg-4">
                                    <img className="img-responsive  img-full" src="images/upholstery/upholstery-fabric.jpg" alt="upholstery fabric" style={{height:'240px', width: '20px'}} />
                                </div>
                                <div className="col-lg-4">
                                    <img className="img-responsive  img-full" src="images/upholstery/upholstery-fabric-1.jpg" alt="upholstery fabric" style={{height:'240px', width: '20px'}} />
                                </div>
                                <div className="col-lg-4">
                                    <img className="img-responsive  img-full" src="images/upholstery/upholstery-leather.jpg" alt="upholstery leather" style={{height:'240px', width: '20px'}} />
                                </div>
                            </div>
                            <div className="col-lg-12"><br /></div>
                            <div className="col-lg-12">
                                <div className="col-lg-4">
                                    <img className="img-responsive  img-full" src="images/sofa/sectional-sofa/sectional-sofa-8-seater-blue.jpg" alt="upholstery fabric sofas" style={{height:'240px', width: '20px' }} />
                                </div>
                                <div className="col-lg-4">
                                    <img className="img-responsive  img-full" src="images/upholstery/upholstery-arabian-sofa.jpg" alt="upholstery fabric sofa" style={{height:'240px', width: '20px' }} />
                                </div>
                                <div className="col-lg-4">
                                    <img className="img-responsive img-full" src="images/upholstery/upholstery-leather-sofa.jpg" alt="upholstery leather sofa" style={{height:'240px', width: '20px' }} />
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="row">
                        <div className="box">
                            <div className="col-lg-4">
                                <img className="img-responsive  img-responsive img-full img-left" src="images/index-images/sofa-repair.jpg" alt="" style={{paddingTop: '70px'}} />
                            </div>
                            <div className="col-lg-8">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>Upholstery Fabrics and Materials</strong>
                                </h2>
                                <hr />
                                <p style={{ lineHeight: '190%' }}>We will also work with clients who provide their own upholstery weight and quality fabrics. Just message us on WhatsApp to have us bring our fabric sample catalogs directly to your home or office. We will be happy to guide you on how to purchase materials appropriate for your project.</p>
                                <ul className="list-unstyled" style={{ fontSize: '1.25em', lineHeight: '1.6', color: '#000' }}>

                                    <li>
                                        <ul>
                                            <li>Upholstery fabric & leather catalogs.</li>
                                            <li>All latest fabric patterns, colors all types of leather.</li>
                                            <li>Free Collection and Delivery Service.</li>

                                        </ul>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="box">
                            <div className="col-lg-12">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>To Know About Pricing Details Message us on WhatsApp Your Sofa Images to Us</strong><br /><br />

                                    &nbsp; &nbsp;<img src="images/social/WhatsApp-icon.png" style={{ height: '40px', width: '40px' }} />&nbsp; <strong style={{ fontSize: '30px' }}>+973-35575189 </strong>
                                    <br /><br /> <a className="btn btn-danger center-block" href="tel:+973-35575189" role="button">Add to Contact</a>

                                </h2>
                                <hr />
                            </div>
                        </div>
                    </div>

                    {/* /.social  */}
                    <div className="row">
                        <div className="box">
                            <div className="col-lg-12">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>Follow us :</strong> &nbsp; &nbsp; <a href="https://www.facebook.com/balushome" target="_blank"><img src="images/social/icon_facebook.png" /></a> &nbsp;<a href="https://twitter.com/Balus_Home" target="_blank"><img src="images/social/icon_twitter.png" /></a><a href="https://plus.google.com/b/113220093015145729926/113220093015145729926/about?gmbpt=true&hl=en-GB" target="_blank"> <img src="images/social/Google_plus.png" width="36" height="36" /></a><a href="https://www.instagram.com/balushome/" target="_blank"> <img src="images/social/Instagram.png" height="34" width="34" /></a><a href="https://www.pinterest.com/balushome/" target="_blank"> <img src="images/social/Pinterest.png" /></a>
                                </h2>
                                <hr />
                            </div>
                        </div>


                    </div>

                </div>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <p>Copyright &copy; balushome.com 2017</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Upholstery;