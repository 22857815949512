import React, { Component } from 'react';
import CommonUtils from '../utils/CommonUtils';
import InstagramEmbed from 'react-instagram-embed';

class ContactUs extends Component {
    componentDidMount = () => {
        CommonUtils.toggleTab("contact");
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12">
                            <hr />
                            <h2 className="intro-text text-center">Contact
                    <strong> Balus Home | Al Shumoe Upholstery </strong>
                            </h2>
                            <hr />
                        </div>
                        <div className="col-md-8">
                            {/* < Embedded Google Map using an iframe - to select your location find it on Google maps and paste the link as the iframe src. If you want to use the Google Maps API instead then have at it!  */}

                            <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.6317091291025!2d50.4928893150287!3d26.143548683464843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjbCsDA4JzM2LjgiTiA1MMKwMjknNDIuMyJF!5e0!3m2!1sen!2s!4v1493136570410"></iframe>

                        </div>
                        <div className="col-md-4">
                            <p><img src="images/social/WhatsApp-icon.png" />&nbsp; <strong>+973-35575189 </strong></p>
                            <p>Email: <strong><a href="mailto:name@example.com">support@balushome.com</a></strong></p>
                            <p>Address:
                        <strong>Bldg 181, Road 304, Block 1203
                            <br />Madinat Hamad (Hamad Town), Bahrain</strong>
                            </p>
                            <p>Website Developer | Business Analyst
                        <strong>Jithin Balakrishnan</strong> &nbsp; <a href="https://www.facebook.com/jithinbalakrishna" target="_blank"> <img src="images/social/icon_facebook.png" /></a>&nbsp;<a href="https://www.linkedin.com/in/jithin-balakrishnan-57050028?trk=hp-identity-name" target="_blank"><img src="images/social/linkedin.png" height="34" width="34" /></a>
                            </p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>

                <div className="row">
                    <div className="box">
                        <div className="col-lg-12">
                            <hr />
                            <h2 className="intro-text text-center">|<strong>SOCIAL</strong>|</h2>
                            <hr />
                            <div className="col-lg-1  img-responsive"></div>
                            <div className="col-lg-6  img-responsive" style={{ marginRight: '20px' }}>
                                <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fbalushome%2Fvideos%2F2016936548543827%2F&show_text=0&width=560" width="560" height="369" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" ></iframe>
                                <br /><br />
                            </div>
                            <div className="col-lg-2  img-responsive">
                                <InstagramEmbed
                                    url='https://www.instagram.com/p/BbuKh1al8by/?utm_source=ig_web_copy_link'
                                    maxWidth={320}
                                    hideCaption={false}
                                    containerTagName='div'
                                    protocol=''
                                    injectScript
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="container">
                        <div className="row" >
                            <div className="col-lg-12 text-center">
                                <p>Copyright &copy; balushome.com 2017 <br />
                                    <a href="privacy-terms&conditions.html" style={{ color: 'black' }}>Privacy policy </a>&nbsp;|&nbsp;  <a href="privacy-terms&conditions.html" style={{ color: 'black' }}>Terms and Conditions</a></p>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>
        )
    }
}

export default ContactUs;