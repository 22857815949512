import React from 'react';
import { Link } from "react-router-dom";

const Header = props => {
    return (
        <div>
            <div className="brand">BALU'S HOME</div>

            <div className="address-bar"><strong>Quality meet Demands </strong> </div>
            <nav className="navbar navbar-default" role="navigation">
                <div className="container">
                    {/* Brand and toggle get grouped for better mobile display */}
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        {/* navbar-brand is hidden on larger screens, but visible when the menu is collapsed --> */}
                        <a className="navbar-brand" href="index.html">Balu's Home</a>
                    </div>
                    {/* <Collect the nav links, forms, and other content for toggling  */}
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <li id="home-li-wrapper" className="active">
                                <Link to="/">Home</Link>
                            </li>
                            <li id="about-li-wrapper">
                                <Link id="about_nav" to="/about">About Us</Link>
                            </li>
                            <li id="services-li-wrapper">
                                <Link id="services_nav" to="/services">Services</Link>
                            </li>
                            <li id="contact-li-wrapper">
                                <Link id="contact_nav" to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    {/* /.navbar-collapse  */}
                </div>
                {/* /.container  */}
            </nav>
        </div>
    )
}

export { Header };