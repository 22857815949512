import React, { Component } from 'react';
import CommonUtils from '../utils/CommonUtils';
import {Link} from "react-router-dom";

class Services extends Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
           
          };
        this.handleUpholsterClick = this.handleUpholsterClick.bind(this);  
      }
    componentDidMount = () => {
        CommonUtils.toggleTab("services");
    }
    handleUpholsterClick = () => {
        this.props.history.push('/services/upholstery')
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="box">
                        <div className="row">
                            <div className="col-lg-6">
                                {/* <div className="box" style= {{border: 'solid grey'}} onClick={this.handleUpholsterClick}> */}
                                <div className="box" style= {{border: 'solid grey'}}>
                                    <Link to="/upholstery">
                                    {/* <a href="/services/upholstery"> */}
                                        <div className="col-lg-6">
                                            <img className="img-responsive  img-responsive img-full img-left" src="images/upholstery/upholstery-fabric-sofa.jpg" alt="upholstery sofa" style={{height: '200px'}} />
                                        </div>
                                            <div className="col-lg-6">
                                                <p><u>Sofa Upholstery, Furniture Repair & Reupholstery Services at Bahrain | Balu's Home.com</u> </p>
                                                <p> Al Shumoe upholstery has been serving as a furniture and furnishing supplier in the Bahrain market for over 10 years. Our superior...</p>
                                            </div>
                                    {/* </a> */}
                                    </Link>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="box" style= {{border: 'solid grey'}}>
                                        <Link to="/reupholstery">
                                        {/* <a href="services-reupholstery.html"> */}
                                            <div className="col-lg-6">
                                                <img className="img-responsive  img-responsive img-full img-left" src="images/reupholstery/reupholstery-4.jpg" alt="reupholstery sofa" style={{height: '200px'}} />
                                            </div>
                                                <div className="col-lg-6">
                                                    <p><u>Sofa Re-upholstery, Furniture Repair & Upholstery Services at Bahrain | Balu's Home.com </u></p>
                                                    <p>We can repair and reupholstery any item of furniture, be it leather or fabric, old or new, including Sofa, Couch, Office Fur...</p>
                                                </div>
                                            {/* </a> */}
                                            </Link>
                                     </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }
            }
            
export default Services;