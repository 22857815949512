import React, { Component } from 'react';
import CommonUtils from '../utils/CommonUtils';

class AboutUs extends Component {
    componentDidMount = () => {
        CommonUtils.toggleTab("about");
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12">
                            <hr />
                            <h2 className="intro-text text-center">About
                        <strong>Balu's Home | AL Shumoe Upholstery</strong>
                            </h2>
                            <hr />
                        </div>
                        <div className="col-md-6">
                            <img className="img-responsive img-border-left" src="images/index-images/slide-2.jpg" alt="" />
                        </div>
                        <div className="col-md-6">
                            <p>We fabricate luxurious sofas in our workshop based on your demand and design. We value our Customers satisfaction and design products with great precision. We give high focus on customers choices in colour, leather/fabric, cushion etc. catalogues are available. Our product are profitable and productive to customer as we have no mediator. We sell products directly from the retailer. We provide free delivery. We celebrate 15 years of promising experience. All the products are Made in Bahrain.</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12">
                            <hr />
                            <h2 className="intro-text text-center">Our
                        <strong>Works</strong>
                            </h2>
                            <hr />
                        </div>
                        <div className="col-sm-4 text-center">
                            <img className="img-responsive" src="images/index-images/work-2.jpg" alt="Home sofas" />
                            <h3>Home Sofas

                    </h3>
                        </div>
                        <div className="col-sm-4 text-center">
                            <img className="img-responsive" src="images/index-images/work-1.jpg" alt="Office sofas" />
                            <h3>Office Sofas

                    </h3>
                        </div>
                        <div className="col-sm-4 text-center">
                            <img className="img-responsive" src="images/index-images/work-3.jpg" alt="Arabian sofas" />
                            <h3>Arabian Sofas

                    </h3>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12">
                            <hr />
                            <h2 className="intro-text text-center"><strong>Reviews & Ratings</strong>

                            </h2>
                            <hr />
                        </div>
                        <div className="col-lg-12">
                            <div className="col-lg-6">
                                <div className="fb-post" data-href="https://www.facebook.com/kim.chadwick/posts/10154150823606317:0" data-width="500" data-show-text="false"><blockquote cite="https://www.facebook.com/kim.chadwick/posts/10154150823606317:0" className="fb-xfbml-parse-ignore"><p>Excellent quality sofa and made exactly how we requested. Would definitely recommend!</p>Posted by <a href="#" role="button">Kim Chadwick</a> on&nbsp;<a href="https://www.facebook.com/kim.chadwick/posts/10154150823606317:0">Monday, 14 November 2016</a></blockquote></div>
                            </div>
                            <div className="col-lg-6 img-responsive" >
                                <p> To see more Reviews and Ratings like and visit our Facebook page </p>

                                <div className="fb-page"
                                    data-href="https://www.facebook.com/balushome"
                                    data-width="380"
                                    data-hide-cover="false"
                                    data-show-facepile="false"
                                    data-show-posts="false"></div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs;