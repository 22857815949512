import React, { Component } from 'react';
import CommonUtils from '../utils/CommonUtils';

class ReUpholstery extends Component {
    componentDidMount = () => {
        CommonUtils.toggleTab("services");
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12 text-center ">
                            <h2 className="brand-before center-block">
                                <small>Welcome to</small>
                            </h2>
                            <h1 className="brand-name" ><img className="img-responsive center-block" src="images/index-images/balushome_png.png" /> </h1>
                            <hr className="tagline-divider" />
                            <h2>
                                <small>Reg.
                        <strong>Al Shuome Upholstery</strong>
                                </small><br />
                                <small><strong>Services | Sofa Reupholstery</strong></small>

                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="box">
                        <div className="col-lg-4">
                            <img className="img-responsive  img-full img-left" src="images/reupholstery/reupholstery-7.jpg" alt="" style={{ paddingTop: '70px' }} />
                        </div>
                        <div className="col-lg-8">
                            <hr />
                            <h2 className="intro-text text-center">
                                <strong>Reupholster your sofa</strong>
                            </h2>
                            <hr />
                            <p style={{ lineHeight: '190%' }}>By repairing & reupholstering (or recovering) furniture, you can maintain the integrity of your beloved items and enjoy them for many many years to come.</p>
                            <p style={{ lineHeight: '190%' }}>That is where Balu's Home.com can help!</p>
                            <p style={{ lineHeight: '190%' }}>How much to reupholster a sofa?? Depends on the suite, material & current condition!</p>
                            <p style={{ lineHeight: '190%' }}>Repair & Re-upholstery is a cost effective option to restore your furniture at a fraction of the replacement cost.

                </p>
                        </div>


                    </div>
                </div>

                <div className="row">
                    <div className="box">
                        <div className="col-lg-8">
                            <hr />
                            <h2 className="intro-text text-center">
                                <strong>Reupholstery Items Of Furniture </strong>
                            </h2>
                            <hr />
                            <p style={{ lineHeight: '190%' }}>We can repair and reupholstery any item of furniture, be it leather or fabric, old or new, including;</p>

                            <ul className="list-unstyled" style={{ fontSize: '1.25em', lineHeight: '1.6', color: '#000' }}>

                                <li>
                                    <ul>
                                        <li>Sofa</li>
                                        <li>Couch</li>
                                        <li>Office furniture</li>
                                        <li>Dining chairs</li>
                                        <li>Kitchen chairs</li>
                                    </ul>
                                </li>


                            </ul>

                        </div>
                        <div className="col-lg-4">

                            <img className="img-responsive  img-responsive img-full" src="images/reupholstery/reupholstery-5.jpg" alt="" style={{ paddingTop: '70px' }} />

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="box">
                        <div className="col-lg-4">
                            <img className="img-responsive  img-responsive img-full img-left" src="images/index-images/sofa-repair.jpg" alt="" style={{ paddingTop: '70px' }} />
                        </div>
                        <div className="col-lg-8">
                            <hr />
                            <h2 className="intro-text text-center">
                                <strong>Furniture Repair Services</strong>
                            </h2>
                            <hr />
                            <p style={{ lineHeight: '190%' }}>We offer a range of furniture repair service for both leather and fabric, including;</p>
                            <ul className="list-unstyled" style={{ fontSize: '1.25em', lineHeight: '1.6', color: '#000' }}>

                                <li>
                                    <ul>
                                        <li>Framework</li>
                                        <li>Springs</li>
                                        <li>Tears</li>
                                        <li>Replacement Panels in fabric or leather</li>
                                        <li>Replacement cushions</li>
                                        <li>Replacement foam</li>
                                    </ul>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="box">
                        <div className="col-lg-12">
                            <hr />
                            <h2 className="intro-text text-center">
                                <strong>We guide you through the whole re-upholstery process</strong></h2>
                            <hr />
                            <ul className="list-unstyled" style={{ fontSize: '1.25em', lineHeight: '1.6', color: '#000' }}>

                                <li>
                                    <ul>
                                        <li>Contact us and we can discuss  your requirements. Include information on the size of the item to be reupholstered. Ideally Send us a picture of the item.</li>
                                        <li>Let us know which Fabric you would like it to be upholstered in. We have a large selection and it would be good to visit our display so you pick a fabric to your taste.</li>
                                        <li>We will provide you with a free quotation detailing the price including the material(fabric/leather).</li>
                                        <li>Once agreed, we can arrange to collect the furniture and start the reupholstery.</li>
                                        <li>Get your new reupholstered furniture in around 2-3 weeks!</li>

                                    </ul>
                                </li>
                                <h2 className="text-center">
                                    <img src="images/social/WhatsApp-icon.png" style={{ height: '40px', width: '40px' }} />&nbsp; <strong style={{ fontSize: '30px' }}>+973-35575189 </strong>
                                    <br /><br /> <a className="btn btn-danger center-block" href="tel:+973-35575189" role="button">Add to Contact</a></h2>
                            </ul>


                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="box">
                        <div className="col-lg-12">
                            <hr />
                            <h2 className="intro-text text-center">
                                <strong>Follow us :</strong> &nbsp; &nbsp; <a href="https://www.facebook.com/balushome" target="_blank"><img src="images/social/icon_facebook.png" /></a> &nbsp;<a href="https://twitter.com/Balus_Home" target="_blank"><img src="images/social/icon_twitter.png" /></a><a href="https://plus.google.com/b/113220093015145729926/113220093015145729926/about?gmbpt=true&hl=en-GB" target="_blank"> <img src="images/social/Google_plus.png" width="36" height="36" /></a><a href="https://www.instagram.com/balushome/" target="_blank"> <img src="images/social/Instagram.png" height="34" width="34" /></a><a href="https://www.pinterest.com/balushome/" target="_blank"> <img src="images/social/Pinterest.png" /></a>
                            </h2>
                            <hr />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default ReUpholstery;