const CommonUtils = {
    toggleTab: function (selectedTab) {
        let tabClass = {
            "home": "home-li-wrapper",
            "about": "about-li-wrapper",
            "contact": "contact-li-wrapper",
            "services": "services-li-wrapper"
        }
        for (var key in tabClass) {
            var element = document.getElementById(tabClass[key]);
            element.classList.remove("active");
        }
        // console.log("toggle tab");
        element = document.getElementById(tabClass[selectedTab]);
        element.classList.add("active");
    }
}

export default CommonUtils;