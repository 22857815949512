import React, { Component } from 'react';
import CommonUtils from '../utils/CommonUtils';

class Home extends Component {
    componentDidMount = () => {
        CommonUtils.toggleTab("home");
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="box">
                            <div className="col-lg-12 text-center">
                                <div id="carousel-example-generic" className="carousel slide">
                                    <ol className="carousel-indicators hidden-xs">
                                        <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
                                        <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                                        <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                                    </ol>
                                    <div className="carousel-inner">
                                        <div className="item active">
                                            <img className="img-responsive img-full" src="images/index-images/slide-1.jpg" alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-responsive img-full" src="images/index-images/slide-2.jpg" alt="" />
                                        </div>
                                        <div className="item">
                                            <img className="img-responsive img-full" src="images/index-images/slide-3.jpg" alt="" />
                                        </div>
                                    </div>
                                    {/*  Controls  */}
                                    <a className="left carousel-control" href="#carousel-example-generic" data-slide="prev">
                                        <span className="icon-prev"></span>
                                    </a>
                                    <a className="right carousel-control" href="#carousel-example-generic" data-slide="next">
                                        <span className="icon-next"></span>
                                    </a>

                                </div>
                                <h2 className="brand-before">
                                    <small>Welcome to</small>
                                </h2>
                                <h1 className="brand-name" ><img className="img-responsive center-block" src="images/index-images/balushome_png.png" /> </h1>
                                <hr className="tagline-divider"></hr>
                                <h2>
                                    <small>Reg.<strong>Al Shuome Upholstery</strong>
                                    </small><br />
                                    <small><strong>Sofa Manufacturer | Curtain Works | Whole Saler</strong></small>

                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="box">
                            <div className="col-lg-4">
                                <img className="img-responsive  img-responsive img-full img-left" src="images/index-images/intro-pic.jpg" alt="" style={{ paddingTop: '70px' }} />
                            </div>
                            <div className="col-lg-8">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>design sofa for your home</strong>
                                </h2>
                                <hr />
                                <p style={{ lineHeight: '190%' }}>We intend to personalise the home environment with our world className home furniture, Office furniture, Single Seaters etc with unbeatable quality. Our furniture comes with enhanced comfort made under great precision and care. We are connected with trusted sellers across Bahrain</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="box">
                            <div className="col-lg-8">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>Buy sofas and curtains for your office, restaurant, hotels etc </strong>
                                </h2>
                                <hr />
                                <p>Al Shumoe Upholstery in Bahrain has been in the business of manufacturing custom sofas, sectional sofas, chairs, and reupholster works from the frame up since 2000.</p>
                                <p>We started by making slip-covered sofas and have evolved into much more. We area wholesale only supplier that sells to the trade.  We are one of the very few sofa manufacturers in the country that can make it your way and keep our word. With well over 100 frames to choose from, we help you offer your customer many options to make this a one-of-a-kind custom centerpiece for their home,office,hotels etc .</p>
                            </div>
                            <div className="col-lg-4">
                                <img className="img-responsive  img-responsive img-full" src="images/index-images/intro-pic2.jpg" alt="" style={{ paddingTop: '70px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="box">
                            <div className="col-lg-4">
                                <img className="img-responsive  img-responsive img-full img-left" src="images/index-images/sofa-repair.jpg" alt="" style={{ paddingTop: '70px' }} />
                            </div>
                            <div className="col-lg-8">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>do you want to reupholster your sofa? </strong>
                                </h2>
                                <hr />
                                <p>By repairing & reupholstering (or recovering) furniture, you can maintain the integrity of your beloved items and enjoy them for many many years to come.That is where Balu's Home.com can help!</p>
                                <p>How much to reupholster a sofa?? Depends on the suite, material & current condition!. Repair & Re-upholstery is a cost effective option to restore your furniture at a fraction of the replacement cost. We can repair and reupholster ANY item of furniture, be it leather or fabric, old or new.</p>
                                <p>Click here to know about our  <a href="services-upholstery.html"> <b>Upholstery service</b> </a> &nbsp;& &nbsp;  <a href="services-reupholstery.html"> <b>Re-upholstery service</b> </a>  </p>
                            </div>
                        </div>
                    </div>

                    {/* social */}
                    <div className="row">
                        <div className="box">
                            <div className="col-lg-12">
                                <hr />
                                <h2 className="intro-text text-center">
                                    <strong>Follow us :</strong> &nbsp; &nbsp; <a href="https://www.facebook.com/balushome" target="_blank"><img src="images/social/icon_facebook.png" /></a> &nbsp;<a href="https://twitter.com/Balus_Home" target="_blank"><img src="images/social/icon_twitter.png" /></a><a href="https://plus.google.com/b/113220093015145729926/113220093015145729926/about?gmbpt=true&hl=en-GB" target="_blank"> <img src="images/social/Google_plus.png" width="36" height="36" /></a><a href="https://www.instagram.com/balushome/" target="_blank"> <img src="images/social/Instagram.png" height="34" width="34" /></a><a href="https://www.pinterest.com/balushome/" target="_blank"> <img src="images/social/Pinterest.png" /></a>
                                    <hr />
                                </h2>
                            </div>

                        </div>
                    </div>
                    <footer>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <p>Copyright &copy; balushome.com 2017</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>

        )
    }
}

export default Home;